import classNames from "classnames"
import Immutable from "immutable"
import React from "react"
import { instanceOf, func, string } from "prop-types"
import ContentEditable from "../common/ContentEditable"
import contentStyles from "../../styles/content.module.styl"
import styles from "../../styles/decision.module.styl"
import textStyles from "../../styles/texts.module.styl"

const otherFactorsPlaceholder = "Type here..."

const clearPlaceholder = e => {
  if (e.target.innerHTML === otherFactorsPlaceholder) e.target.innerHTML = ""
}

const Decision = ({
  options,
  setDecision,
  setDecisionContent,
  decision,
  decisionDescription,
  otherFactorsContent,
  otherFactorsDescription,
}) => (
  <div>
    <h2>Your decision</h2>
    <div className={classNames(contentStyles.mt20, textStyles.plainText)}>
      {decisionDescription}
    </div>
    <div className={styles.optionsRow}>
      {options.map(option => {
        return (
          <button
            className={classNames(styles.option, styles.button, {
              [styles.btnChecked]: option.get("value") === decision,
            })}
            key={option.get("value")}
            onClick={setDecision(option.get("value"))}
            dangerouslySetInnerHTML={{ __html: option.get("label") }}
          />
        )
      })}
    </div>
    <div className={styles.otherFactors}>
      <div className={textStyles.plainText}>{otherFactorsDescription}</div>
      <div>
        <ContentEditable
          className={styles.otherFactorsContent}
          contentEditable
          onFocus={clearPlaceholder}
          onInput={setDecisionContent}
          onBlur={setDecisionContent}
          content={otherFactorsContent}
        />
      </div>
    </div>
  </div>
)

Decision.propTypes = {
  decision: string,
  decisionDescription: string.isRequired,
  otherFactorsContent: string,
  otherFactorsDescription: string.isRequired,
  setDecision: func.isRequired,
  setDecisionContent: func.isRequired,
  options: instanceOf(Immutable.List).isRequired,
}

Decision.defaultProps = {
  decision: null,
  decisionContent: null,
}

export default Decision
