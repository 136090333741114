import React from "react"
import { string } from "prop-types"
import classNames from "classnames"
import ArrowLink from "./ArrowLink"
import styles from "../../styles/content.module.styl"
import navigationStyles from "../../styles/navigation.module.styl"

const ArrowNavigation = ({
  prevLink,
  nextLink,
  prevLinkText,
  nextLinkText,
}) => {
  return (
    <div
      className={classNames(
        navigationStyles.arrowNavigation,
        styles.justifyBetween
      )}
    >
      <div className={navigationStyles.navigationArrow}>
        <ArrowLink type="left" linkTo={prevLink} linkText={prevLinkText} />
      </div>
      <div className={navigationStyles.navigationArrow}>
        <ArrowLink type="right" linkTo={nextLink} linkText={nextLinkText} />
      </div>
    </div>
  )
}

ArrowNavigation.propTypes = {
  prevLink: string,
  nextLink: string,
  prevLinkText: string,
  nextLinkText: string,
}

ArrowNavigation.defaultProps = {
  prevLink: null,
  nextLink: null,
  prevLinkText: "back",
  nextLinkText: "next",
}

export default ArrowNavigation
