import classNames from "classnames"
import { shape, string } from "prop-types"
import React from "react"
import _ from "lodash"
import styles from "../../styles/header.module.styl"
import Breadcrumbs from "./Breadcrumbs"
import Header from "./Header"
import Illustration from "./Illustration"

const SubPageHeader = ({
  title,
  question,
  pageId,
  groupId,
  hasGroups,
  subPage,
  group,
  logo,
}) => {
  const headerClassess = classNames(styles.header, {
    [styles.headerLow]: !_.isEmpty(subPage),
    [styles.headerHigh]: _.isEmpty(subPage),
  })

  return (
    <Header className={headerClassess}>
      {!group && hasGroups ? (
        <>
          <div className={styles.title}>{title}</div>
          <div className={styles.separator} />
          <div className={styles.question}>{question}</div>
          <div className={styles.illustrationContainer}>
            <Illustration logo={logo} />
          </div>
        </>
      ) : (
        <>
          <div className={styles.inlineIllustration}>
            <div className={styles.subPageQuestion}>
              {group ? group.title : title}
            </div>
            <div className={styles.illustrationContainer}>
              <Illustration logo={logo} />
            </div>
          </div>
          <Breadcrumbs
            pageId={pageId}
            groupName={groupId}
            activeSubPageId={subPage}
          />
        </>
      )}
    </Header>
  )
}

SubPageHeader.propTypes = {
  pageId: string,
  groupId: string,
  subPage: string,
  group: shape({
    name: string,
    title: string,
  }),
  title: string,
}

SubPageHeader.defaultProps = {
  pageId: "",
  groupId: "",
  subPage: "",
  title: "",
  group: null,
}

export default SubPageHeader
