import React from "react"
import _ from "lodash"
import classNames from "classnames"
import { string, func, number, bool, object } from "prop-types"
import Circle from "./Circle"
import styles from "../../styles/components.module.styl"

const DefaultToggle = ({ currentValue, dragging }) => (
  <Circle
    className={currentValue !== null ? null : styles.rangeInputToggleNoValue}
    content={currentValue !== null ? currentValue : ""}
    size={40}
  />
)

const RangeInputTickMark = ({ value, label, style }) => (
  <div className={styles.rangeInputTickMark} style={style}>
    <div className={styles.rangeInputTickMarkValue}>{value}</div>
    <div className={styles.rangeInputTickMarkSymbol} />
    <div className={styles.rangeInputTickMarkLabel}>{label}</div>
  </div>
)

const captureMoveEvents = (onMoveCb, onMoveEndCb) => {
  // there should be no other react on movements other than ours
  const bodyPointerEvents = document.body.style["pointer-events"]
  const bodyUserSelect = document.body.style["user-select"]
  document.body.style["pointer-events"] = "none"
  document.body.style["user-select"] = document.body.style[
    "-moz-user-select"
  ] = document.body.style["-ms-user-select"] = document.body.style[
    "-webkit-user-select"
  ] = "none"
  // this will restore body styles and unregister callbacks once mouse is up
  const removeListeners = evt => {
    document.removeEventListener("mousemove", onMoveCb)
    document.removeEventListener("mouseup", removeListeners)
    document.body.style["pointer-events"] = bodyPointerEvents
    document.body.style["user-select"] = document.body.style[
      "-moz-user-select"
    ] = document.body.style["-ms-user-select"] = document.body.style[
      "-webkit-user-select"
    ] = bodyUserSelect
    onMoveEndCb(evt)
  }
  // start capturing
  document.addEventListener("mousemove", onMoveCb)
  document.addEventListener("mouseup", removeListeners)
}

class RangeInput extends React.PureComponent {
  static propTypes = {
    max: number.isRequired,
    min: number.isRequired,
    onChange: func,
    readonly: bool,
    scaleBarClass: string,
    step: number,
    Toggle: func,
    tickMarkClass: string,
    tickMarkStep: number,
    tickMarkLabels: object,
    value: number,
    withToggleArrows: bool,
    showTickMarkValues: bool,
  }

  static defaultProps = {
    readonly: false,
    scaleBarClass: "",
    step: 1,
    tickMarkClass: "",
    tickMarkStep: 1,
    tickMarkLabels: {},
    Toggle: DefaultToggle,
    value: 0,
    withToggleArrows: true,
    showTickMarkValues: true,
  }

  constructor(props) {
    super(props)
    this.state = {
      containerLeft: 0,
      dragging: false,
      stepLength: 1,
    }

    this.updateRangeValueFromPointerPosition = this.updateRangeValueFromPointerPosition.bind(
      this
    )
    this.handleTrackClick = this.handleTrackClick.bind(this)
    this.handleToggleMouseDown = this.handleToggleMouseDown.bind(this)
    this.handleToggleMove = this.handleToggleMove.bind(this)
    this.handleToggleMoveEnd = this.handleToggleMoveEnd.bind(this)
    this.handleDec = this.handleDec.bind(this)
    this.handleInc = this.handleInc.bind(this)
    this.handleKeyDown = this.handleKeyDown.bind(this)
    this.rangeRef = this.rangeRef.bind(this)
    this._measureDOM = this._measureDOM.bind(this)
  }
  rangeRef(el) {
    this.rangeEl = el
  }

  _measureDOM() {
    const { max, min, step } = this.props
    const { width, left } = this.rangeEl.getBoundingClientRect()
    const numOfSteps = (max - min) / step

    return {
      stepLength: width / numOfSteps,
      containerLeft: left,
    }
  }

  getValueOffset(value) {
    const { min, max, step } = this.props
    const clampedValue = _.clamp(value, min, max)
    const numOfSteps = (max - min) / step
    // number of steps to make from min to provided value
    const stepsToVal = (clampedValue - min) / step

    return `${(stepsToVal / numOfSteps) * 100}%`
  }

  updateRangeValueFromPointerPosition(pointerX) {
    if (this.props.readonly) return

    const { min, max, step, value } = this.props
    const { stepLength, containerLeft } = this.state
    const stepIndex = Math.round((pointerX - containerLeft) / stepLength)
    const stepValue = min + stepIndex * step
    const clampedStepValue = _.clamp(stepValue, min, max)

    if (clampedStepValue !== value) {
      this.props.onChange(clampedStepValue)
    }
  }

  handleTrackClick(evt) {
    const { clientX } = evt
    this.setState(this._measureDOM(), () =>
      this.updateRangeValueFromPointerPosition(clientX)
    )
  }

  handleToggleMove(evt) {
    evt.stopPropagation()
    this.updateRangeValueFromPointerPosition(evt.clientX)
  }

  handleToggleMouseDown(evt) {
    if (this.props.readonly) return
    // do not react on arrow buttons mouse down
    if (
      evt.target.classList.contains(styles.rangeInputToggleLeftArrow) ||
      evt.target.classList.contains(styles.rangeInputToggleRightArrow)
    ) {
      return
    }
    this.setState(
      _.extend(this._measureDOM(), {
        dragging: true,
      })
    )
    captureMoveEvents(this.handleToggleMove, this.handleToggleMoveEnd)
  }

  handleToggleMoveEnd(evt) {
    this.setState({
      dragging: false,
    })
  }

  handleInc() {
    const { value, max, onChange, step } = this.props
    if (value === max) return
    const incremented = value + step
    return onChange(incremented > max ? max : incremented)
  }

  handleDec() {
    const { value, min, onChange, step } = this.props
    if (value === min) return
    const decremented = value - step
    return onChange(decremented < min ? min : decremented)
  }

  handleKeyDown(evt) {
    // prevent scrolling with arrow keys
    evt.preventDefault()

    switch (evt.keyCode) {
      case 37:
      case 40:
        return this.handleDec()
      case 38:
      case 39:
        return this.handleInc()
    }
  }

  renderTickMarks() {
    const {
      min,
      max,
      tickMarkStep,
      tickMarkLabels,
      showTickMarkValues,
    } = this.props
    const tickVals = _.range(min, max, tickMarkStep).concat(max)

    return tickVals.map((tickVal, idx) => (
      <RangeInputTickMark
        value={
          showTickMarkValues || idx === 0 || idx === tickVals.length - 1
            ? tickVal
            : null
        }
        key={tickVal}
        style={
          idx === tickVals.length - 1
            ? null
            : { left: this.getValueOffset(tickVal) }
        }
        label={tickMarkLabels[tickVal]}
      />
    ))
  }

  renderToggle() {
    const { Toggle, value, min, max, withToggleArrows, readonly } = this.props
    // TODO: add Touch events to support touch screen users if needed
    return (
      <div
        className={styles.rangeInputToggle}
        onMouseDown={this.handleToggleMouseDown}
        style={{ left: this.getValueOffset(value) }}
      >
        {withToggleArrows && !readonly
          ? [
              value > min ? (
                <button
                  key="arrowDec"
                  className={styles.rangeInputToggleLeftArrow}
                  onClick={this.handleDec}
                />
              ) : null,
              value < max ? (
                <button
                  key="arrowInc"
                  className={styles.rangeInputToggleRightArrow}
                  onClick={this.handleInc}
                />
              ) : null,
            ]
          : null}
        <Toggle currentValue={value} dragging={this.state.dragging} />
      </div>
    )
  }

  render() {
    const { scaleBarClass } = this.props

    return (
      <div
        className={styles.rangeInput}
        onKeyDown={this.handleKeyDown}
        ref={this.rangeRef}
        tabIndex={-1}
      >
        <div className={classNames(styles.rangeInputTrack, scaleBarClass)} />
        <div
          className={styles.rangeInputTickMarks}
          onClick={this.handleTrackClick}
        >
          {this.renderTickMarks()}
        </div>
        {this.renderToggle()}
      </div>
    )
  }
}

export default RangeInput
