import React from "react"
import classNames from "classnames"
import Link from "gatsby-link"
import { string } from "prop-types"
import styles from "../../styles/header.module.styl"

const SUB_PAGES = [
  { id: "", label: "Introduction" },
  { id: "facts", label: "Facts & values" },
  { id: "options", label: "Options" },
  // { id: "risk", label: "Risk" },
  { id: "summary", label: "Summary" },
]

const Breadcrumbs = ({ pageId, groupId, activeSubPageId }) => {
  const linkPrefix = groupId ? `/${pageId}/${groupId}` : `/${pageId}`

  return (
    <div className={styles.breadcrumbs}>
      {SUB_PAGES.map(({ id, label }) => {
        const itemClass = classNames(styles.breadcrumbsItem, {
          [styles.breadcrumbsItemActive]: id === activeSubPageId,
        })

        return (
          <div key={id} className={itemClass}>
            <Link to={`${linkPrefix}/${id}`}>{label}</Link>
          </div>
        )
      })}
    </div>
  )
}

Breadcrumbs.propType = {
  pageId: string.isRequired,
  groupId: string,
  activeSubPageId: string.isRequired,
}

Breadcrumbs.defaultProps = {
  groupId: null,
}

export default Breadcrumbs
