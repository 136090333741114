import React from "react"
import { string } from "prop-types"
import TextContent from "../common/TextContent"
import styles from "../../styles/components.module.styl"

const PanelRecommendation = ({ title, recommendation }) => (
  <div className={styles.panelRecommendation}>
    <h2>{title}</h2>
    {recommendation && (
      <TextContent
        className={styles.panelRecommendationContent}
        content={recommendation}
      />
    )}
  </div>
)

PanelRecommendation.propTypes = {
  title: string,
  recommendation: string.isRequired,
}

PanelRecommendation.defaultProps = {
  title: "",
}

export default PanelRecommendation
