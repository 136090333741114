import React from "react"
import { string, oneOf } from "prop-types"
import styles from "../../styles/headers.module.styl"

const HeaderWithIcon = ({ type, title }) => (
  <div className={styles[`${type}HeaderWithIcon`]}>{title}</div>
)

HeaderWithIcon.propTypes = {
  type: oneOf(["research", "summary", "question", "assessment", "risk"])
    .isRequired,
  title: string.isRequired,
}

export default HeaderWithIcon
