import React from 'react';
import Link from 'gatsby-link';
import { string, oneOf } from 'prop-types';

import styles from '../../styles/navigation.module.styl';

const ArrowLink = ({ type, linkTo, linkText }) => {
  const arrowClassName = type === 'left' ? styles.arrowLeft : styles.arrowRight;
  return (
    linkTo && (
      <Link className={arrowClassName} to={linkTo}>
        {linkText}
      </Link>
    )
  );
};

ArrowLink.propTypes = {
  type: oneOf(['left', 'right']).isRequired,
  linkTo: string,
  linkText: string,
};

ArrowLink.defaultProps = {
  linkTo: null,
  linkText: null,
};

export default ArrowLink;
